import request from '../utils/request'
import config from './config'

/**
 * 单位管理
 */
// 上级监管单位-tree
export function getAuditSubordinateGovernmentList(params) {
  return request({
    url: `${config.CIVIL_HOST}/pensionUnit/getAuditSubordinateGovernmentList`,
    method: 'get',
    params,
  })
}

//审核通过
export function getAuditSubordinateGovernmentPageList(data) {
  return request({
    url: `${config.CIVIL_HOST}/pensionUnit/getAuditSubordinateGovernmentPageList`,
    method: 'post',
    data,
  })
}

//新增-上级监管单位查询
export function getSuperiorGovernmentList(params) {
  return request({
    url: `${config.CIVIL_HOST}/pensionUnitGovernment/getSuperiorGovernmentList`,
    method: 'get',
    params,
  })
}

//新增-上级企业单位查询

export function getSuperiorEnterpriseList(data) {
  return request({
    url: `${config.CIVIL_HOST}/pensionUnitEnterprise/getSuperiorEnterpriseList`,
    method: 'post',
    data,
  })
}
//行政区划
export function getChildrenDict(params) {
  return request({
    url: `${config.CIVIL_HOST}/pensionOrganizationDict/getChildrenDict`,
    method: 'get',
    params
  })
}

//新增、编辑
export function addPensionUnit(data) {
  return request({
    url: `${config.CIVIL_HOST}/pensionUnit/addPensionUnit`,
    method: 'post',
    data
  })
}

//取消监管
export function cancelSupervisePensionUnit(data) {
  return request({
    url: `${config.CIVIL_HOST}/pensionUnit/cancelSupervisePensionUnit`,
    method: 'post',
    data
  })
}

//停用、启用账号
export function enableDisableUnit(data) {
  return request({
    url: `${config.CIVIL_HOST}/pensionUnit/enableDisableUnit`,
    method: 'post',
    data
  })
}

//编辑回显
export function getPensionUnit(params) {
  return request({
    url: `${config.CIVIL_HOST}/pensionUnit/getPensionUnit`,
    method: 'get',
    params
  })
}

//统计数量
export function getStatisticsUnit(params) {
  return request({
    url: `${config.CIVIL_HOST}/pensionUnit/getStatisticsUnit`,
    method: 'get',
    params
  })
}

//重置密码
export function resetPasswordsUnit(data) {
  return request({
    url: `${config.CIVIL_HOST}/pensionUnit/ResetPasswordsUnit`,
    method: 'post',
    data
  })
}

//待审核、审核不通过下级单位列表
export function getSubordinateGovernmentPageList(data) {
  return request({
    url: `${config.CIVIL_HOST}/pensionUnit/getSubordinateGovernmentPageList`,
    method: 'post',
    data
  })
}

//审核单位

export function auditUnit(data) {
  return request({
    url: `${config.CIVIL_HOST}/pensionUnitApproveRecord/auditUnit`,
    method: 'post',
    data
  })
}
